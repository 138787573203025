import {Component, Input, OnInit} from '@angular/core';
import {NamedArchivable} from "../../modelinterfaces/archiveable.model";

@Component({
  selector: 'cmn-archivable-name',
  templateUrl: './archivable-name.component.html',
  styleUrls: ['./archivable-name.component.scss']
})
export class ArchivableNameComponent implements OnInit {

  @Input() value: NamedArchivable;
  @Input() textStyle: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
