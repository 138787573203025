import {DayjsUtil} from "../dayjs.util";
import {Dayjs} from "dayjs";
import {LocalDate, LocalDateTime} from "@js-joda/core";


export class DateConverter {

  private static readonly DATE_FORMAT = 'DD.MM.YYYY HH:mm:ss';

  public static utcDateTimeToTzDateString(datetime: string, tzName: string, format: string = DateConverter.DATE_FORMAT): string {
    if (!datetime) {
      return 'no data';
    }
    return DayjsUtil.instant(new Date(datetime).toUTCString()).tz(tzName).format(format);
  }

  public static utcDateTimeToTzDateStringOrNull(datetime: string, tzName: string, format: string = DateConverter.DATE_FORMAT): string {
    if (!datetime) {
      return null;
    }
    return DayjsUtil.instant(new Date(datetime).toUTCString()).tz(tzName).format(format);
  }

  public static tzDateToUtcDatetimeISOString(tzDate: Date): string {
    return DayjsUtil.instant(tzDate.toUTCString()).toDate().toISOString();
  }

  public static utcDatetimeToTzDate(datetime: string, tzName: string): Date {
    if (!datetime || !tzName) {
      return null;
    }
    return DayjsUtil.instant(new Date(datetime).toUTCString()).tz(tzName).toDate();
  }

  public static secondsToTzDateString(seconds: number, tzName: string, format: string = DateConverter.DATE_FORMAT): string {
    return DayjsUtil.instant(seconds * 1000).tz(tzName).format(format);
  }

  public static secondsToTzDate(seconds: number, tzName: string): Date {
    if (!seconds || !tzName) {
      return null;
    }
    return DayjsUtil.instant(seconds * 1000).tz(tzName).toDate();
  }

  public static toDateString(obj: object): string {
    if (!obj) {
      return null;
    }
    const date: Date = new Date(obj.toString());
    return `${date.getFullYear()}-${DateConverter.zeroPad(date.getMonth() + 1, 2)}-${DateConverter.zeroPad(date.getDate(), 2)}`;
  }

  private static zeroPad(num, places): string {
    const zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join('0') + num;
  }

  public static toLocalDateTimeString(time: Date | Dayjs | LocalDateTime | LocalDate, format: string = "YYYY-MM-DDTHH:mm:ss"): string {
    return DayjsUtil.instant(time).format(format)
  }
}
